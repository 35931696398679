.layout-landing {

	background-color: #2b303a;
	min-height: 100%; }
.l-container {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	@include clr; }

.l-logo {
	margin-bottom: 15px;
	display: inline-block;
	@include m {
		margin-left: 50px; }
	img {
		max-width: 380px;
		@include t {
			max-width: 200px; } } }
.l-promo {
	min-height: 300px;
	position: relative;
	.l-promo__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: "";
		background-image: url('../img/land/bg/videobg.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 75% 50%;
		@include m {
			background-position: 74% 50%;
			background-attachment: scroll; } }
	&__inner {
		padding: 180px 140px 350px;
		@include gpu;
		@include t {
			padding: 180px 80px 350px; }
		@include m {
			padding: 120px 0; } }
	&__title {
		font-size: 36px;
		font-weight: 300;
		line-height: 1.36;
		letter-spacing: 0.5px;
		color: #ffffff;
		text-transform: uppercase;
		@include t {
			font-size: 32px; }
		@include m {
			font-size: 14px;
			text-align: center; } }
	&__sub-title {
		font-size: 36px;
		font-weight: bold;
		line-height: 1.36;
		letter-spacing: 0.5px;
		color: #ffffff;
		text-transform: uppercase;
		@include t {
			font-size: 32px; }

		@include m {
			font-size: 24px;
			text-align: center; } } }

.text-blocks {
	position: relative;
	&__top {
		margin-bottom: 36px; }

	&__wrap {
		position: relative;
		z-index: 2; }
	&__container {
		position: relative; }
	&__arr-t {
		position: absolute;
		min-height: 100%;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 50%;
		border-top: solid 4px white;
		transform: skewY(7deg);
		box-shadow: inset 0px 70px 0px 0px rgb(214,33,39);
		background-color: #2b303a;
		z-index: -0;
		margin-top: -70px;

		&:nth-of-type(2n) {
			transform: skewY(-7deg);
			right: 0;
			left: auto; } }
	&__arr-b {
		position: absolute;
		min-height: 100%;
		top: 0;
		bottom: 0;
		margin: auto;
		float: left;
		width: 50%;
		border-bottom: solid 4px white;
		transform: skewY(7deg);
		box-shadow: inset 0px -70px 0px 0px rgb(214,33,39);
		background-color: #2b303a;
		z-index: -0;
		margin-bottom: -70px;
		@include m {
			margin-bottom: -130px; }
		&:nth-of-type(2n) {
			transform: skewY(-7deg);
			float: right;
			right: 0;
			left: auto; } } }

.line {
	display: block;
	position: absolute;
	background-color: #95979c;
	height: 2px;
	left: 0;
	width: 0;
	transition: 0.5s ease width;

	&::after {
		content: '';
		height: 8px;
		width: 1px;
		background-color: inherit;
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto; }


	&_right {
		left: auto;
		right: 0;
		&::after {
			left: 0;
			right: auto; } } }
.parallax-image {
	padding-top: 30%;
	position: relative;
	@include m {
		padding-top: 0; }
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		@include m {
			position: static; } }
	&_last {
		padding-top: 0;
		padding-bottom: 30%; } }

.l-text {
	&__wrap {
		transition: opacity .1s linear; }
	&_space {
		margin-top: -40px;
		@include m {
			padding-top: 0px  !important;
			margin-top: 0;
			clear: both; } }
	&_space-small {
		@include m {
			margin-top: -70px !important;
			clear: both; } } }
.l-text.is-visible {
	.l-text__wrap {
		opacity: 1;
		transition: opacity .1s linear; }
	.line {
		width: 35%; } }
.l-image {
	position: relative;
	width: 50%;
	@include d {
		padding: 0 15px;
		max-width: 50%; }
	@include m {
		max-width: 100%;
		width: 90%; }
	img {
		@include d {
			max-width: 100%; } }
	&_right {
		float: right;
		margin: 0 -20px;
		@include m {
			float: none;
			margin: 0 auto; } }


	&_left {
		float: left; }
	&_last {
		margin-bottom: -180px;
		@include m {
			padding: 0; }
		img {
			@include m {
				max-width: 120%;
				margin-left: -10%; } } }

	&_center {
		@include m {
			padding: 0;
			min-width: 100%;
 }			// min-height: 150px
		img {
			@include m {
				max-width: 130%;
				margin-left: -33%; } } }

	&_first {
		margin-top: -100px;

		img {
			@include m {
				margin-top: -50px; } }
		@include m; } }

// .parallax-image-hidden
// 	display: none
// 	+m
// 		display: block
// 		opacity: 0
.l-text {
	font-size: 16px;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: 0.2px;
	color: $w;
	@include t {
		font-size: 14px; }

	.l-title {
		padding-bottom: 30px; }
	&__wrap {
		width: 350px;
		padding: 50px 0;
		opacity: 0.4;
		transition: 0.3s ease opacity;
		&.is-visible {
			opacity: 1; }
		@include t {
			width: 320px;
			padding: 40px 0; }
		@include m {
			margin: 0 auto; } }

	&_left {
		float: left;
		margin-left: 140px;
		@include t {
			margin-left: 80px; }
		@include m {
			float: none;
			margin: 0 auto;
			text-align: center; } }
	&_right {
		float: right;
		margin-right: 90px;
		text-align: right;
		@include t {
			margin-right: 60px; }
		@include m {
			float: none;
			margin: 0 auto;
			text-align: center; } } }

.l-title {
	font-size: 36px;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.4px;
	color: #ffffff;
	text-transform: uppercase;
	&-lg {
		padding: 50px 0;
		text-transform: uppercase;
		text-align: center; } }
.l-title-md {
	font-size: 24px;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.4px;
	color: #ffffff;
	text-transform: uppercase;
	@include m {
		font-size: 18px;
		font-weight: 600; } }

.plane {

	// background-attachment: fixed
	position: relative;
	.plane__bg {
		background-image: url('../img/land/bg/img.jpg');
		// min-height: 1050px
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 76% 0;
		position: absolute;
		bottom: 0;
		top: -200px;
		left: 0;
		right: 0;
		@include m {
			background-position: 70% 62%;
			background-size: cover; } }
	@include m {
		min-height: 600px; }
	@include r(480) {
		background-size: 768px 575px;
		background-position: 80% 61%; }
	&__list {
		display: inline-block;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.33;
		letter-spacing: 0.6px;
		color: #ffffff;
		@include r(1024) {
			font-size: 18px; }
		@include m {
			font-size: 14px; }
		li {
			display: block; } }
	&__pointer {
		padding: 0% 20% 5% 28%;
		@include r(1200) {
			padding: 0 24% 5% 16%; }
		@include r(600) {
			padding: 0 5% 5% 29%; }
		@include r(480) {
			padding: 0 5% 5% 10%; } }
	&__img {
		max-width: 80%;
		@include r(1200) {
			min-width: 85%; } }
	&__wrap {
		position: relative;
		padding: 19% 0 22%;
		top: -100px;
		@include m {
			padding: 300px 0 345px;
			top: -70px; }
		@include r(480) {
			// margin-top: -20px
			padding: 152px 0 345px; } }
	&__container {
		@include r(480) {
			margin-bottom: -120px; } } }
.guages {
	margin-top: -75px;
	position: relative;
	position: relative;
	@include r(480) {
		margin-top: -115px; }
	&__wrap {
		position: relative; }
	&__container {
		position: relative;
		padding: 130px 0; }
	&__block {
		position: absolute;
		min-height: 100%;
		top: 0;
		bottom: 0;
		margin: auto;
		// float: left
		left: 1px;
		width: 50%;
		background-image: linear-gradient(173deg, #2b303a, #000000);
		border-top: solid 4px white;
		border-bottom: solid 4px white;
		transform: skewy(7deg);
		box-shadow: inset 0px 70px 0px 0px rgb(214,33,39);
		z-index: 0;
		&:nth-of-type(2n) {
			transform: skewY(-7deg);
			background-image: linear-gradient(-173deg, #2b303a, #000000);
			float: right;
			right: 1px;
			left: auto; } }

	&__list {
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
		@include justify;
		padding: 20px 0;
		@include t {
			padding: 0 15px; }
		@include m {
			text-align-last: auto; } }
	&__item {
		display: inline-block;
		opacity: 0;
		transition-property: opacity, transform;
		transition-duration: .5s;
		transition-timing-function: ease;
		&.item-left {
			transform: translate(-500%, 0); }
		&.item-right {
			transform: translate(500%, 0); }
		&.is-animate {
			opacity: 1;
			transform: translate(0, 0); }
		@include m {
			display: block;
			text-align: center;
			padding-bottom: 70px; } }

	// &__img
	// 	+m
	// 		display: inline-block
	// 		margin: 0 auto
	&__text {
		text-align: center;
		padding: 30px 0 0 0;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.33;
		letter-spacing: 0.2px;
		text-align-last: center;
		color: #ffffff;
		@include m {
			// display: inline-block
			text-align: center;
			padding: 20px 0; } } }
.soon {
	padding: 80px 0 106px;
	text-align: center;
	position: relative;
	&::before {
		// content: ''
		position: absolute;
		width: 100%;
		background-image: url('../img/land/bg/arrow-simple.png');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 8%;
		left: 0;
		top: -8%;
		z-index: 2; } }

@keyframes soon_item {
	0% {
		opacty: 0;
		transform: translate(0, -150px); }
	100% {
		opacity: 1;
		transform: translate(0, -0px); } }

/* The element to apply the animation to */


.soon-list {
	max-width: 960px;
	width: 100%;
	display: block;
	margin: 0 auto;
	border: solid 1px #ffffff;
	padding: 30px 0;
	position: relative;
	@include clr;
	@include t {
		max-width: 95%; }
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 70px;
		height: 70px;
		background-image: url('../img/land/img/center.svg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		@include m {
			display: none; } }
	&__title {
		font-size: 24px;
		font-weight: 500;
		letter-spacing: 0.3px;
		text-align: center;
		color: #ffffff;
		padding-bottom: 25px;
		text-transform: uppercase;
		@include m {
			font-size: 18px;
			padding-bottom: 20px; } }
	&__item {
		width: 50%;
		float: left;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
		letter-spacing: 0.2px;
		text-align: center;
		color: #bbbbbb;
		padding: 35px 0;
		transition-property: opacity, transform;
		transition-duration: 1.5s;
		transition-timing-function: ease;
		opacity: 0;
		transform: translate(0, 0px);
		&.is-animate {
			opacity: 1;
			transform: translate(0, 0px);
 }			// opacity: 0
		@include m {
			display: block;
			float: none;
			margin: 0 auto;
			width: 100%;
			max-width: 320px; }
		@include r(480) {
			max-width: 260px;
			font-size: 14px;
			line-height: 1.7; } }
	&__text {
		max-width: 350px;
		display: inline-block; }
	&__img {
		display: inline-block;
		margin-right: 8px; } }








.text-bg {
	background-image: url('../img/land/bg/glash.png');
	height: 104px;
	line-height: 104px;
	background-repeat: no-repeat;
	background-size: 100% contain;
	background-position: center center;
	margin: 80px 0;
	&__title {
		display: inline-block;
		vertical-align: middle; } }
.l-nav {
	display: inline-block;
	@include clr;
	margin-top: 70px;
	a {
		float: left;
		margin-left: 50px;
		@include m {
			margin-left: 40px; }
		&:first-of-type {
			margin-left: 0; }
		.icon {
			vertical-align: middle;
			font-size: 1.5em;
			fill: white;
			@include m {
				font-size: 1.4em; }
			&-youtube {
				font-size: 1.3em;
				@include m {
					font-size: 1em; } }

			&-inst {
				font-size: 1.6em; } } } }

.l-social {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: block;
	text-align: center;
	line-height: 46px;
	@include m {
		width: 40px;
		height: 40px;
		line-height: 36px; }
	&_fb {
		background-color: #3b5998; }
	&_twitter {
		background-color: #55acee; }
	&_youtube {
		background-color: #ce1312;
		.icon {
			font-size: 1.4em; } }
	&_inst {
		background-image: url('../img/inst.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		.icon {
			font-size: 1.6em; } } }

::-webkit-contacts-auto-fill-button, ::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0; }
.l-form {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	padding: 0 20px; }
.l-form__title {
	padding-bottom: 25px; }
.l-input {
	width: 100%;
	height: 50px;
	border: solid 1px #ffffff;
	background-color: transparent;
	margin-bottom: 10px;
	font-weight: 400;
	text-align: center;
	color: white;
	font-size: 18px;
	@include placeholder {
		font-size: 18px;
		letter-spacing: 0.3px;
		// line-height: 1.33
		color: white; } }
.l-area {
	width: 100%;
	height: 150px;
	border: solid 1px #ffffff;
	background-color: transparent;
	margin-bottom: 10px;
	padding: 10px 0;
	font-weight: 400;
	text-align: center;
	color: #ffffff;
	font-size: 18px;
	@include placeholder {
		font-size: 18px;
		letter-spacing: 0.3px;
		line-height: 1.33;
		color: #ffffff; } }
.l-btn {
	max-width: 350px;
	width: 100%;
	height: 50px;
	background-color: #d62127;
	display: inline-block;
	font-size: 18px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: 0.3px;
	text-align: center;
	color: #ffffff;
	text-transform: uppercase;
	margin-top: 25px;
	transition: 0.4s ease all;
	&:hover {
		background-color: #a2161a; } }
.parallax {
	// background-attachment: fixed
 }	// background-attachment: local
