.icon-arrow-left {
  width: 0.57em;
  height: 1em;
  fill: currentColor;
}
.icon-arrow-right {
  width: 0.57em;
  height: 1em;
  fill: currentColor;
}
.icon-box {
  width: 0.9em;
  height: 1em;
  fill: currentColor;
}
.icon-cloud {
  width: 1.5em;
  height: 1em;
  fill: currentColor;
}
.icon-compete {
  width: 1.08em;
  height: 1em;
  fill: currentColor;
}
.icon-compete1 {
  width: 1.05em;
  height: 1em;
  fill: currentColor;
}
.icon-connect {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-edit {
  width: 1.05em;
  height: 1em;
  fill: currentColor;
}
.icon-educator {
  width: 7.8em;
  height: 1em;
  fill: currentColor;
}
.icon-explore {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-facebook {
  width: 0.56em;
  height: 1em;
  fill: currentColor;
}
.icon-fb {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-gift {
  width: 0.81em;
  height: 1em;
  fill: currentColor;
}
.icon-googleplus {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-home {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-inst {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-license {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-linkedin {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-message {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-multi {
  width: 0.88em;
  height: 1em;
  fill: currentColor;
}
.icon-report {
  width: 1.03em;
  height: 1em;
  fill: currentColor;
}
.icon-star {
  width: 1.05em;
  height: 1em;
  fill: currentColor;
}
.icon-stats {
  width: 0.91em;
  height: 1em;
  fill: currentColor;
}
.icon-storage {
  width: 1.1em;
  height: 1em;
  fill: currentColor;
}
.icon-sunglasses {
  width: 2.38em;
  height: 1em;
  fill: currentColor;
}
.icon-text {
  width: 0.79em;
  height: 1em;
  fill: currentColor;
}
.icon-transfer {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-tw {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.icon-twitter {
  width: 1.15em;
  height: 1em;
  fill: currentColor;
}
.icon-wallet {
  width: 1.05em;
  height: 1em;
  fill: currentColor;
}
.icon-youtube {
  width: 1.39em;
  height: 1em;
  fill: currentColor;
}
