@import url('https://fonts.googleapis.com/css?family=Exo+2:200,300,400,500,600');
@mixin f {
  font-family: 'Exo 2', sans-serif; }

@mixin d {
  @media only screen and (max-width: "1200px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin mt {
  @media only screen and (min-width: "1024px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin mm {
  @media only screen and (min-width: "768px") {
    @content; } }

$blue: #00aaff;
$dark: #336699;
$light: #acedff;
$cl: #2f4858;
$border: #9b9b9b;
$cn: #4a4a4a;
$w: #bbbbbb;

body {
  @include f;
  font-size: 16px;
  font-weight: 200;
  color: $cl;
  line-height: 1.3;
  -webkit-font-smoothing: subpixel-antialiased; }
button,
input,
textarea,
select {
  font: inherit; }
input,
textarea {
  border-radius: 0;
  -webkit-appearance: none; }
a {
  color: $blue;
  text-decoration: none; }
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200; }

.svg-shadow {
  filter: drop-shadow(0 3px 3px rgba(0,0,0,.15));
  &_sm {
    filter: drop-shadow(2px 1px 1px rgba(0,0,0,.23)); } }

.out {
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden; }

.btn {
  display: inline-flex;
  min-width: 170px;
  padding: 5px 20px 8px;
  border: 1px solid transparent;
  background-color: $blue;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  letter-spacing: .2px;
  transition: all .2s;
  &:hover {
    @include mt {
      background: #FFF;
      border-color: $blue;
      color: $blue; } }
  &_stroke {
    background: transparent;
    border: 1px solid $blue;
    color: $blue;
    &:hover {
      @include mt {
        background: $blue;
        color: #FFF; } } } }

.title {
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: $dark;
  @include m {
    text-align: center; }
  &_white {
    color: #FFF; } }

.center {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 40px;
  @include m {
    padding: 0 20px; }
  &_lg {
    max-width: 1040px; }
  &_l {
    max-width: 780px; }
  &_md {
    max-width: 700px; } }

.container {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  &_benefits {
    background-image: url(../img/bg-benefits.jpg);
    @include m {
      background-size: 180% auto;
      background-position: 26% calc(100% - 240px); } }
  &_features {
    background-image: url(../img/bg-features.jpg);
    @include m {
      background-size: 320% auto;
      background-position: 14% calc(100% - 180px); } }
  &_overview {
    background-image: url(../img/bg-overview.jpg);
    @include m {
      background-size: 400% auto, auto auto;
      background-position: 24% calc(100% - 75px), 0 0;
      background-image: url(../img/bg-overview.jpg), linear-gradient(to bottom, #fff calc(100% - 80px), #afeeff calc(100% - 80px)); } }
  &_prices {
    background-image: url(../img/bg-prices.jpg);
    @include m {
      background-size: 200% auto;
      background-position: 93% 100%; } }
  &_blog {
    padding: 65px 0 105px;
    @include m {
      padding: 55px 0 50px; } } }

.header {
  position: relative;
  z-index: 99;
  &__center {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between; }
  &__logo {
    position: relative;
    margin-left: -10px;
    padding: 20px 10px;
    font-size: 0;
    z-index: 2;
    @include m {
      margin-left: -20px; }
    .icon {
      font-size: 30px;
      fill: $blue;
      @include m {
        font-size: 20px; } } }
  &__wrap {
    display: flex;
    align-items: center;
    @include t {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      min-height: 100vh;
      padding-bottom: 65px;
      background: #FFF;
      &.visible {
        display: block; } } }
  &__nav {
    display: flex;
    align-items: center;
    @include t {
      display: block;
      padding-top: 90px; } }
  &__nav.visible {
    @include t {
      padding-top: 40px; } }
  &__nav.visible &__item:not(&__item_sub) {
    @include t {
      display: none; } }
  &__nav.visible &__drop {
    @include t {
      display: block;
      top: 120px; } }
  &__item:hover &__name {
    @include mt {
      color: $blue;
      &:after {
        border-top-color: $blue;
        transform: rotate(180deg); } } }
  &__item:hover &__drop {
    @include mt {
      opacity: 1;
      visibility: visible; } }
  &__item.active:not(&__item_sub) &__link {
    @include mt {
      &:after {
        content: '';
        position: absolute;
        top: 56px;
        left: 20px;
        right: 20px;
        height: 1px;
        background: $blue; } } }
  &__item.active:not(&__item_sub) &__name {
    color: $blue; }
  &__item_sub:hover &__link {
    &:before {
      opacity: 1; } }
  &__item_sub &__link {
    cursor: default;
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -10px;
      @include arr(14,12,$border,t);
      z-index: 4;
      @include t {
        display: none; } }
    &:before {
      opacity: 0;
      transition: opacity .2s; }
    &:after {
      bottom: -1.5px;
      border-color: transparent transparent #fff transparent; } }
  &__item_sub &__name {
    &:after {
      content: '';
      display: block;
      position: relative;
      top: 2px;
      margin-left: 5px;
      @include arr(9,8,rgba(47, 71, 88, 0.5),b);
      transition: border-color .2s; } }
  &__link {
    display: flex;
    position: relative;
    height: 75px;
    padding: 0 27px;
    align-items: center;
    justify-content: center;
    @include t {
      height: auto;
      padding: 30px 20px; } }
  &__name {
    display: flex;
    font-size: 16px;
    color: $cl;
    letter-spacing: .9px;
    transition: color .2s;
    align-items: center;
    @include t {
      font-size: 24px; } }
  &__drop {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border-bottom: 1px solid $blue;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
    @include t {
      display: none;
      border: none;
      opacity: 1;
      visibility: visible;
      transition: none; } }
  &__sub {
    position: relative;
    padding: 20px;
    flex: 1 0 20%;
    border: 1px solid $border;
    border-width: 1px 1px 0;
    @include t {
      display: block;
      padding: 20px 50px;
      border: none;
      flex: 1 1 100%; }
    &:not(:last-child) {
      border-width: 1px 0 0 1px; }
    &:first-child,
    &:last-child {
      padding: 20px 50px;
      @include t {
        padding: 20px 50px; } }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      background: $blue;
      opacity: 0;
      transition: opacity .2s;
      @include t {
        right: auto;
        width: 5px;
        height: 100%; } } }
  &__sub.active {
    &:after {
      opacity: 1; } }
  &__sub:hover &__icon,
  &__sub.active &__icon {
    .icon {
      fill: $blue; } }
  &__sub:hover &__title,
  &__sub.active &__title {
    color: $blue; }
  &__head {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    @include t {
      justify-content: flex-start; } }
  &__icon {
    margin-right: 7px;
    font-size: 0;
    .icon {
      font-size: 20px;
      transition: fill .2s; } }
  &__title {
    color: $cl;
    transition: color .2s; }
  &__text {
    font-size: 12px;
    color: $border;
    letter-spacing: .7px;
    text-align: center;
    @include t {
      text-align: left; } }
  &__subscribe {
    margin-left: 25px;
    @include t {
      margin: 45px 0 0; } }
  &__burger {
    display: none;
    position: relative;
    width: 45px;
    height: 60px;
    margin-right: -10px;
    background: transparent;
    z-index: 2;
    @include t {
      display: block; }
    @include m {
      margin-right: -20px; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 25px;
      left: 10px;
      height: 1px;
      transition: all .4s; }
    &:before {
      top: 22px;
      background: $blue; }
    &:after {
      top: 30px;
      box-shadow: 0 1px 0 0 $blue inset, 0 8px 0 0 $blue; }
    &.active {
      &:before {
        transform: translateY(8px) rotate(45deg); }
      &:after {
        box-shadow: 0 1px 0 0 $blue inset, 0 0 0 0 $blue;
        transform: rotate(135deg); } } }
  &.active {
    @include mt {
      margin-bottom: 152px; }
    @include t {
      margin-bottom: 50px; } }
  &.active &__item_sub.active &__link {
    &:before {
      opacity: 1; } }
  &.active &__item_sub.active &__name {
    @include mt {
      color: $blue;
      &:after {
        border-top-color: $blue;
        transform: rotate(180deg); } } }
  &.active &__drop {
    @include mt {
      opacity: 1;
      visibility: visible; } } }

.subscribe {
  &__title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.21;
    letter-spacing: 0.3px; }
  &__text {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.2px; }
  &__form {
    display: flex;
    @include t {
      display: block;
      text-align: center; } }
  &__input,
  &__btn {
    width: 130px;
    min-width: 130px; }
  &__input {
    height: 35px;
    padding: 0 10px 2px;
    border: solid 1px rgba($cl,.5);
    background: transparent;
    font-size: 12px;
    letter-spacing: .7px;
    @include t {
      display: block;
      width: 220px;
      margin: 0 auto 20px; } }
  &_footer &__form {
    display: block;
    @include t {
      text-align: left; }
    @include m {
      text-align: center; } }
  &_footer &__input {
    display: block;
    width: 220px;
    margin-bottom: 10px;
    border-color: rgba(#fff,.5);
    color: #FFF;
    @include placeholder-color(#fff);
    @include t {
      margin: 0 0 10px; }
    @include m {
      margin: 0 auto 10px; } }
  &_footer &__btn {
    min-width: 150px; } }

.main {
  position: relative;
  min-height: 1540px;
  padding-top: 150px;
  overflow: hidden;
  @include m {
    min-height: 1210px;
    padding-top: 80px; }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    right: 0;
    width: 1920px; }
  &__wrap {
    position: relative;
    margin-bottom: 616px;
    z-index: 2;
    @include m {
      margin-bottom: 326px; } }
  &__head {
    max-width: 460px;
    margin-bottom: 100px;
    font-size: 36px;
    color: $blue;
    line-height: 1.25;
    letter-spacing: 2px;
    @include m {
      margin-bottom: 50px;
      font-size: 24px;
      line-height: 1.4;
      letter-spacing: 1.3px; }
    br {
      @include m {
        display: none; } } }
  &__scene {
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 10px;
      width: 2540px;
      height: 700px;
      border-radius: 0 0 100% 50%;
      box-shadow: 0 500px 0 0 #fff;
      transform: translateX(-50%);
      @include m {
        width: 700px; } } }
  &__layer {
    position: absolute;
    left: 50%; }
  &__pic {
    display: block;
    position: relative; }
  &__arctic-back {
    top: 424px;
    transform: translateX(397px);
    @include d {
      transform: translateX(297px); } }
  &__arctic-back &__pic {
    @include m {
      width: 120px; } }
  &__lighthouse {
    top: 108px;
    transform: translateX(388px);
    @include d {
      transform: translateX(288px); }
    @include m {
      top: 142px;
      transform: translateX(88px); } }
  &__lighthouse &__pic {
    @include m {
      width: 73px; } }
  &__light {
    position: absolute;
    top: -46px;
    right: 50%;
    width: 580px;
    height: 250px;
    transform-origin: 100% 50%;
    animation: light 8s infinite linear;
    z-index: -2;
    @include m {
      top: -87px; } }
  &__arctic-front {
    top: 412px;
    transform: translateX(100px);
    @include d {
      transform: translateX(0); }
    @include m {
      top: 290px;
      transform: translateX(-54px); } }
  &__arctic-front &__pic {
    @include m {
      width: 460px; } }
  &__boat {
    top: 688px;
    transform: translateX(168px);
    @include m {
      top: 390px;
      transform: translateX(-89px); } }
  &__boat &__pic {
    @include m {
      width: 80px; } }
  &__wave-1,
  &__wave-2,
  &__wave-3,
  &__wave-4,
  &__wave-5 {
    transform: translateX(-50%) rotate(0); }
  &__wave-1 {
    top: 490px;
    @include m {
      top: 284px;
      transform: translateX(-53.4%) rotate(0); } }
  &__wave-2 {
    top: 534px;
    @include m {
      top: 322px;
      transform: translateX(-59.4%) rotate(0); } }
  &__wave-3 {
    top: 562px;
    @include m {
      top: 364px;
      transform: translateX(-43%) rotate(0); } }
  &__wave-4 {
    top: 740px;
    @include m {
      top: 432px;
      transform: translateX(-48%) rotate(0); } }
  &__wave-5 {
    top: 880px;
    background: linear-gradient(rgba(#fff,0) 140px, $dark 140px);
    @include m {
      top: 482px;
      bottom: 0;
      transform: translateX(-48%) rotate(0); } }
  &__wave-1 &__pic {
    transform-origin: 50% 0;
    animation: wave 3s infinite linear; }
  &__wave-2 &__pic {
    transform-origin: 25% 0;
    animation: wave 3s infinite linear; }
  &__wave-3 &__pic {
    transform-origin: 50% 0;
    animation: wave 3s infinite linear;
    @include m {
      width: 868px; } }
  &__wave-4 &__pic {
    transform-origin: 75% 0;
    animation: wave 3s infinite linear;
    @include m {
      width: 680px; } }
  &__wave-5 &__pic {
    transform-origin: 50% 0;
    animation: wave 3s infinite linear;
    @include m {
      width: 620px; } }
  &__boat &__pic {
    transform-origin: 25% 0;
    animation: boat 3s infinite linear; }
  &__whale {
    top: 1072px;
    transform: translateX(-486px);
    @include m {
      top: 930px;
      transform: translateX(-210px) scale(.4); } }
  &__whale &__pic {
    transform-origin: 80% 50%; }
  &__bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  &__bubble {
    position: absolute;
    animation: bubble 3s infinite linear;
    &:nth-child(1) {
      bottom: 346px;
      right: 58px;
      width: 12px;
      animation-delay: .6s; }
    &:nth-child(2) {
      bottom: 316px;
      right: 14px;
      width: 18px;
      animation-delay: .5s; }
    &:nth-child(3) {
      bottom: 262px;
      right: 52px;
      width: 20px;
      animation-delay: .4s; }
    &:nth-child(4) {
      bottom: 236px;
      right: 22px;
      width: 12px;
      animation-delay: .3s; }
    &:nth-child(5) {
      bottom: 214px;
      right: -16px;
      width: 10px;
      animation-delay: .2s; }
    &:nth-child(6) {
      bottom: 183px;
      right: 42px;
      width: 22px;
      animation-delay: .1s; }
    &:nth-child(7) {
      bottom: 152px;
      right: -26px;
      width: 32px; } }
  &__info {
    position: relative;
    padding-left: 50%;
    color: #fff;
    font-weight: 300;
    z-index: 2;
    @include m {
      padding: 0; } }
  &__title {
    margin-bottom: 50px;
    font-size: 24px;
    letter-spacing: .3px;
    line-height: 1.2;
    @include m {
      font-size: 18px;
      line-height: 1.38;
      letter-spacing: .2px; } }
  &__item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 34px; }
    @include m {
      align-items: flex-start; } }
  &__icon {
    display: flex;
    margin-right: 15px;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 95px;
    &:after {
      content: '';
      width: 30px;
      height: 1px;
      background: #FFF; } }
  &__text {
    font-size: 14px;
    letter-spacing: .2px;
    @include m {
      padding-top: 14px; } } }

@keyframes light {
  0 {
    transform: rotateY(0); }
  25%,
  75% {
    opacity: 0; }
  50%,
  100% {
    opacity: 1; }
  100% {
    transform: rotateY(360deg); } }

@keyframes wave {
  0, 100% {
    transform: translateY(5px) rotate(0); }
  50% {
    transform: translateY(5px) rotate(-2deg); } }

@keyframes boat {
  0, 100% {
    transform: translateY(15px) rotate(0); }
  50% {
    transform: translateY(15px) rotate(-2deg); } }

@keyframes bubble {
  0% {
    opacity: 0;
    transform: translate(0px,-5px); }
  20% {
    opacity: 1;
    transform: translate(-10px,-10px); }
  40% {
    transform: translate(-20px,-15px); }
  60% {
    transform: translate(-10px,-20px); }
  80% {
    opacity: 1;
    transform: translate(0px,-25px); }
  100% {
    opacity: 0;
    transform: translate(10px,-30px); } }

@keyframes whale {
  0,
  100% {
    transform: rotate(0); }
  50% {
    transform: rotate(4deg); } }

.text {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #9b9b9b; }

.list {
  &__item {
    position: relative;
    padding-left: 15px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.2px;
    color: #9b9b9b;
    &:not(:last-child) {
      margin-bottom: 20px; }
    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 5px;
      height: 5px;
      background: $dark;
      border-radius: 50%; } }
  &_lg &__item {
    &:not(:last-child) {
      margin-bottom: 35px; } } }

.info {
  position: relative;
  &__wrap {
    display: flex;
    padding-top: 50px;
    flex-wrap: wrap;
    @include m {
      padding-top: 60px; } }
  &__block {
    position: relative;
    width: 42%;
    z-index: 10;
    @include m {
      width: 100%; }
    &:nth-child(1) {
      margin-bottom: 245px;
      @include m {
        margin: 0 0 70px; } }
    &:nth-child(2) {
      margin-top: 140px;
      @include m {
        margin: 0 0 70px; } }
    &:nth-child(4) {
      margin-top: 125px;
      @include m {
        margin: 70px 0; } }
    &:nth-child(2n) {
      width: 58%;
      padding-left: 10%;
      @include m {
        width: 100%;
        padding: 0; } } }
  &__title {
    margin-bottom: 30px;
    @include m {
      margin-bottom: 22px;
      font-size: 18px;
      letter-spacing: .2px;
      text-align: left; } }
  &__list {
    margin-bottom: 30px; }
  &__layer {
    position: absolute; }
  &__pic {
    display: block;
    position: relative; }
  &__cloud {
    &-1 {
      left: 50%;
      top: 50px;
      margin-left: 184px;
      animation: cloud 5s infinite linear;
      @include m {
        width: 44px;
        top: 0;
        width: 50px;
        margin-left: 0; } }
    &-2 {
      left: 50%;
      top: 0;
      margin-left: 351px;
      animation: cloud 4s infinite linear reverse;
      @include m {
        width: 44px;
        top: -25px;
        width: 70px;
        margin-left: 70px; } }
    &-3 {
      left: 50%;
      top: 50px;
      margin-left: 707px;
      animation: cloud 3s infinite linear;
      @include m {
        display: none; } } }
  &__scene-1 {
    top: 380px;
    right: 50%;
    margin-right: 150px;
    @include m {
      top: 360px;
      margin-right: -90px; } }
  &__scene-1 &__ice {
    @include m {
      display: none; } }
  &__scene-1 &__iceberg {
    top: -96px;
    right: 269px;
    @include m {
      display: none; } }
  &__scene-1 &__penguin {
    top: 66px;
    right: 46px;
    @include m {
      dsplay: none; } }
  &__scene-1 &__bird {
    left: 100%;
    bottom: 3px;
    animation: bird 4s infinite linear reverse;
    @include m {
      width: 30px;
      animation: none; } }
  &__scene-2 {
    top: 500px;
    left: 50%;
    margin-left: -47px; }
  &__scene-2 &__ice {
    @include m {
      display: none; } }
  &__scene-2 &__mountain {
    top: -5px;
    right: 0;
    @include m {
      display: none; } }
  &__scene-2 &__iceberg {
    top: -133px;
    left: 433px;
    transform-origin: 50% 100%;
    @include d {
      left: 333px;
      @include m {
        display: none; } } }
  &__scene-2 &__penguin-1 {
    top: 20px;
    left: 125px;
    transform-origin: 50% 100%;
    @include m {
      display: none; } }
  &__scene-2 &__penguin-2 {
    top: 8px;
    left: 145px;
    transform-origin: 50% 100%;
    @include m {
      display: none; } }
  &__scene-2 &__bird-1 {
    bottom: 104px;
    left: 307px;
    animation: bird 4s infinite linear;
    @include m {
      bottom: auto;
      top: 628px;
      left: 119px;
      width: 50px;
      animation: none; } }
  &__scene-2 &__bird-2 {
    bottom: 40px;
    left: 284px;
    animation: bird 4s 1s infinite linear;
    @include m {
      top: 227px;
      bottom: auto;
      left: 135px;
      width: 60px;
      animation: none; } }
  &__foot {
    display: flex;
    position: relative;
    height: 540px;
    margin-top: -70px;
    background: linear-gradient(to bottom, #fff, #cff5ff 250px, $dark 250px);
    align-items: flex-end;
    @include m {
      height: 500px;
      background: linear-gradient(to bottom, #fff 155px, $dark 155px); } }
  &__foot &__center {
    width: 100%; }
  &__foot &__ice-1 {
    top: 96px;
    right: 50%;
    margin-right: 327px;
    @include m {
      display: none; } }
  &__foot &__ice-2 {
    top: 123px;
    left: 50%;
    margin-left: -271px;
    @include m {
      display: none; } }
  &__foot &__iceberg-1 {
    top: 55px;
    right: 50%;
    margin-right: 10px;
    @include m {
      top: 50px;
      margin-right: -97px;
      width: 240px; } }
  &__foot &__iceberg-2 {
    top: 73px;
    left: 50%;
    margin-left: 266px;
    animation: iceberg 2s infinite linear;
    @include m {
      display: none; } }
  &__foot &__fish {
    top: 176px;
    left: 50%;
    margin-left: 108px;
    transform-origin: 50% 100%;
    animation: fish 5s infinite linear;
    @include m {
      display: none; } }
  &__foot &__bubbles {
    bottom: 42px;
    left: 50%;
    margin-left: 370px; }
  &__content {
    position: relative;
    padding: 0 0 80px calc(100% - 380px);
    z-index: 4;
    @include m {
      max-width: 100%;
      padding: 0 0 40px; } }
  &__text {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 1px;
    font-weight: 300;
    color: #fff;
    @include m {
      margin-bottom: 50px;
      font-size: 14px;
      line-height: 1.3;
      letter-spacing: .8px; }
    p {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 25px; } } } }

@keyframes cloud {
  0, 50%, 100% {
    transform: translateX(0); }
  25% {
    transform: translateX(-10px); }
  75% {
    transform: translateX(10px); } }

@keyframes iceberg {
  0, 50%, 100% {
    transform: rotate(0); }
  25% {
    transform: rotate(2deg); }
  75% {
    transform: rotate(-2deg); } }

@keyframes penguin {
  0, 50%, 100% {
    transform: rotate(0); }
  25% {
    transform: rotate(-6deg); }
  75% {
    transform: rotate(6deg); } }

@keyframes bird {
  0, 50%, 100% {
    transform: rotate(0); }
  25% {
    transform: translateY(-10px) rotate(18deg); }
  75% {
    transform: translateY(10px) rotate(-18deg); } }

@keyframes fish {
  0, 50%, 100% {
    transform: rotate(0); }
  25% {
    transform: translateY(2px) rotate(1deg); }
  75% {
    transform: translateY(-2px) rotate(-1deg); } }

.social {
  display: flex;
  &__link {
    display: flex;
    width: 30px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 0;
    &:not(:last-child) {
      margin-right: 20px; }
    .icon {
      fill: #fff;
      &-facebook {
        font-size: 16px; }
      &-twitter {
        font-size: 12px; }
      &-googleplus {
        font-size: 16px; }
      &-linkedin {
        font-size: 14px; } } } }

.footer {
  padding: 25px 0 30px;
  margin-top: 1px;
  background: $cl;
  color: #fff;
  @include m {
    padding: 50px 0;
    text-align: center; }
  &__center {
    display: flex;
    justify-content: space-between;
    @include m {
      display: block; } }
  &__subscribe {
    width: 280px;
    @include m {
      width: 100%;
      margin-bottom: 47px; } }
  &__wrap {
    text-align: right;
    @include m {
      text-align: center; } }
  &__text {
    margin-bottom: 20px;
    padding-top: 4px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.6px; }
  &__email {
    font-weight: 500;
    color: #FFF; }
  &__social {
    margin-top: 20px;
    opacity: .7;
    justify-content: flex-end;
    @include m {
      margin-top: 30px;
      justify-content: center; } } }

.grid {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  &__item {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    z-index: 10;
    @include m {
      margin-bottom: 50px; }
    &_sm {
      width: calc(50% - 22px);
      margin-bottom: 25px;
      @include m {
        width: 100%;
        margin-bottom: 50px; } } }
  &__item_sm &__wrap {
    padding: 20px; }
  &__wrap {
    position: relative;
    height: 100%;
    padding: 30px 50px 40px;
    border: 1px solid $blue;
    background: #fff;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.18);
    flex: 1 0 100%;
    @include m {
      padding: 20px; } }
  &__head {
    display: flex;
    margin-bottom: 20px;
    align-items: center; }
  &__head_lg {
    @include m {
      display: block;
      margin-bottom: 30px; } }
  &__head_lg &__icon {
    width: 75px;
    height: 50px;
    margin-right: 0;
    flex: 0 0 75px;
    @include m {
      width: 100%;
      margin-bottom: 20px;
      flex: 0 0 100%; } }
  &__head + &__wrap {
    height: auto; }
  &__icon {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    flex: 0 0 30px;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      fill: $dark; }
    .icon-home {
      font-size: 30px; }
    .icon-sunglasses {
      font-size: 12px; }
    .icon-cloud {
      font-size: 19px; }
    .icon-text {
      font-size: 38px; }
    .icon-report {
      font-size: 30px; }
    .icon-multi {
      font-size: 34px; }
    .icon-stats {
      font-size: 32px; } }
  &__pic {
    display: block;
    @include m {
      display: block;
      margin: 0 auto; } }
  &__title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    color: $dark; }
  &__t {
    margin: 0;
    @include m {
      font-size: 18px;
      line-height: 1.38; } }
  &__scene {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1960px;
    height: 300px;
    transform: translateX(-50%);
    overflow: hidden;
    text-align: center;
    @include m {
      bottom: 0;
      width: 110%;
      height: 120px;
      transform: translateX(-55%); } }
  &__layer {
    position: absolute;
    left: 10px;
    animation: grid-wave 6s infinite linear;
    @include m {
      width: calc(150% - 20px); } }
  &__wave-1 {
    top: 20px;
    transform-origin: 30% 0;
    @include m {
      top: 0; } }
  &__wave-2 {
    top: 85px;
    transform-origin: 50% 0;
    @include m {
      top: 20px; } }
  &__wave-3 {
    top: 140px;
    transform-origin: 70% 0;
    @include m {
      top: 40px; } }
  &_features &__item:nth-child(1) &__wrap,
  &_features &__item:nth-child(2) &__wrap {
    min-height: 232px; }
  &_features &__item:nth-child(3) &__wrap,
  &_features &__item:nth-child(4) &__wrap {
    min-height: 377px; }
  &_overview &__wrap {
    padding-left: 77px;
    @include m {
      padding-left: 20px; } }
  &_cases {
    @include m {
      margin: 0 -10px; } }
  &_cases &__wrap {
    padding: 27px 50px 30px 77px;
    @include m {
      padding: 27px 20px 30px; } }
  &_cases &__item {
    margin: 0;
    &:nth-child(1) {
      padding-bottom: 300px;
      background: url(../img/bg-cases-1.jpg) no-repeat 50% 100%;
      @include m {
        padding-bottom: 90px;
        background-size: 180% auto;
        background-position: 20% 100%; } }
    &:nth-child(2) {
      padding: 50px 0 320px;
      background: url(../img/bg-cases-2.jpg) no-repeat 50% 0, $light;
      @include m {
        padding: 180px 0 150px;
        background-size: 180% auto;
        background-position: 20% 0; } }
    &:nth-child(3) {
      padding: 0 0 320px;
      background: url(../img/bg-cases-3.jpg) no-repeat 50% 100%, $dark;
      @include m {
        padding-bottom: 370px;
        background-size: 240% auto;
        background-position: 30% 100%; } }
    &:nth-child(4) {
      padding: 0;
      background: linear-gradient(#2e4857,#274354); } }
  &_simple &__wrap {
    @include m {
      padding: 0;
      border: none;
      box-shadow: none;
      background: none; } } }

@keyframes grid-wave {
  0, 50%, 100% {
    transform: rotate(0); }
  25% {
    transform: rotate(-1deg); }
  75% {
    transform: rotate(1deg); } }

.trial {
  padding: 165px 0 70px;
  text-align: right;
  @include m {
    padding: 200px 0 70px 0;
    text-align: left; }
  &__wrap {
    position: relative;
    padding-left: calc(100% - 400px);
    @include m {
      padding: 0 calc(100% - 400px) 0 0; } }
  &__text {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 500;
    color: $dark;
    line-height: 1.44;
    letter-spacing: 1px;
    @include m {
      font-weight: 200; } }
  &__btn {
    position: relative;
    z-index: 10; }
  &__scene {
    position: absolute;
    top: 72px;
    left: 50%;
    margin-left: 292px;
    @include t {
      margin-left: 92px; } }
  &__wave {
    display: block;
    animation: trial 2s infinite linear reverse; }
  &__boat {
    position: absolute;
    left: 41px;
    bottom: 8px;
    transform-origin: 0% 100%;
    animation: trial 2s infinite linear; }
  &__breath {
    position: absolute;
    bottom: 70px;
    right: 250px;
    width: 100px;
    height: 100px;
    transform: rotate(30deg);
    @include m {
      right: 200px; } }
  &__bubble {
    position: absolute;
    animation: bubble 3s infinite linear;
    &:nth-child(1) {
      bottom: 346px;
      right: 58px;
      width: 12px;
      animation-delay: .6s; }
    &:nth-child(2) {
      bottom: 316px;
      right: 14px;
      width: 18px;
      animation-delay: .5s; }
    &:nth-child(3) {
      bottom: 262px;
      right: 52px;
      width: 20px;
      animation-delay: .4s; }
    &:nth-child(4) {
      bottom: 236px;
      right: 22px;
      width: 12px;
      animation-delay: .3s; }
    &:nth-child(5) {
      bottom: 214px;
      right: -16px;
      width: 10px;
      animation-delay: .2s; }
    &:nth-child(6) {
      bottom: 183px;
      right: 42px;
      width: 22px;
      animation-delay: .1s; }
    &:nth-child(7) {
      bottom: 152px;
      right: -26px;
      width: 32px; } }
  &_features {
    padding-top: 425px;
    @include m {
      padding-top: 430px; } }
  &_features &__text {
    font-weight: 200;
    line-height: 1.61; }
  &_overview {
    padding: 345px 0 120px;
    text-align: left;
    @include m {
      padding: 280px 0 70px; } }
  &_overview &__wrap {
    padding: 0 calc(100% - 400px) 0 0; }
  &_overview &__text {
    @include m {
      font-weight: 500; } }
  &_overview &__scene {
    @include m {
      display: none; } }
  &_cases {
    padding: 160px 0 150px;
    background: linear-gradient(#274354,#103349);
    text-align: left;
    @include m {
      padding: 70px 0 370px; } }
  &_cases &__wrap {
    padding: 0 calc(100% - 400px) 0 0; }
  &_cases &__text {
    font-weight: 200;
    color: #fff; }
  &_cases &__scene {
    top: -40px;
    margin-left: -210px;
    @include t {
      top: -60px;
      margin-left: -350px; }
    @include m {
      top: 240px;
      margin: 0;
      transform: translateX(-71%) scale(.8); } } }

@keyframes trial {
  0, 50%, 100% {
    transform: rotate(0); }
  25% {
    transform: translateY(-2px) rotate(1deg); }
  75% {
    transform: translateY(2px) rotate(-1deg); } }

.prices {
  display: flex;
  margin-left: -20px;
  padding-bottom: 110px;
  flex-wrap: wrap;
  @include m {
    max-width: 225px;
    margin: 0 auto;
    padding-bottom: 80px; }
  &__item {
    width: calc(25% - 20px);
    margin: 0 0 20px 20px;
    padding: 15px 30px 34px;
    border: solid $blue;
    border-width: 5px 1px 1px;
    background: #fff;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.18);
    text-align: center;
    @include t {
      width: calc(50% - 20px); }
    @include m {
      width: 100%;
      margin: 0 0 70px; }
    &.active {
      background: $light; } }
  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: $blue;
    letter-spacing: .3px; }
  &__list {
    margin: 0 -15px 42px 0; }
  &__info {
    display: flex;
    margin-bottom: 39px;
    align-items: center; }
  &__icon {
    display: flex;
    height: 20px;
    margin-right: 15px;
    align-items: center;
    .icon {
      font-size: 20px; }
    .icon-storage {
      font-size: 18px; } }
  &__text {
    font-size: 14px;
    letter-spacing: .2px; }
  &__wrap {
    display: flex;
    margin-bottom: 30px;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 12px;
    color: $blue;
    & + & {
      margin-bottom: 35px; } }
  &__currency,
  &__time {
    position: relative;
    letter-spacing: .6px; }
  &__currency {
    top: 3px; }
  &__time {
    top: 17px; }
  &__value {
    font-size: 36px;
    letter-spacing: .4px;
    font-weight: 300;
    line-height: .88; }
  &__desc {
    width: 100%;
    padding-top: 5px;
    color: $border; }
  &__btn {
    display: block;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    & + & {
      margin-top: 10px; } } }

.field {
  margin-bottom: 20px;
  &__wrap {
    position: relative; }
  &__input,
  &__area {
    width: 100%;
    border: 1px solid rgba($cl,.5);
    font-size: 12px;
    letter-spacing: .7px; }
  &__input {
    height: 35px;
    padding: 0 20px 2px; }
  &__area {
    display: block;
    height: 180px;
    padding: 10px 20px; }
  &__required {
    position: absolute;
    top: -4px;
    left: 100%;
    margin-left: 10px;
    color: $blue;
    font-weight: 400;
    @include m {
      margin-left: 3px; } } }

.search {
  display: flex;
  margin-bottom: 50px;
  &__field {
    margin: 0;
    flex: 1 0 auto; }
  &__btn {
    margin-left: 20px;
    @include m {
      display: none; } } }

.l {
  display: flex;
  @include m {
    display: block; }
  &__container {
    width: 77%;
    padding-right: 9%;
    @include m {
      width: 100%;
      padding: 0 0 70px; } }
  &__aside {
    width: 23%;
    @include m {
      width: 100%; } } }

.post {
  margin-bottom: 70px;
  &__item {
    margin-bottom: 50px;
    &:not(:last-child) {
      padding-bottom: 50px;
      border-bottom: 1px solid $blue; } }
  &__meta {
    display: flex;
    margin-bottom: 20px;
    font-size: 12px;
    letter-spacing: .3px;
    @include m {
      margin-bottom: 30px; } }
  &__detail {
    color: $border;
    &:not(:last-child) {
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid $border; } }
  &__title {
    display: inline-flex;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.48;
    color: $blue;
    @include m {
      font-size: 18px;
      line-height: 1.55; } }
  &__content {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .2px;
    line-height: 1.7;
    color: $cn;
    li {
      &:before {
        content: '- '; } } }
  &__more {
    font-size: 12px;
    letter-spacing: .6px; }
  &__action {
    display: flex;
    margin-top: 70px;
    @include m {
      display: block;
      margin-top: 50px;
      text-align: center; } }
  &__btn {
    margin-right: 20px;
    @include m {
      margin: 0 20px;
      &:not(:last-child) {
        margin-bottom: 20px; } } }
  &_single {
    @include m {
      margin-bottom: 100px; } }
  &_single &__title {
    margin-bottom: 50px;
    @include m {
      margin-bottom: 20px; } } }

.pager {
  display: flex;
  @include m {
    justify-content: center; }
  &__link {
    display: flex;
    height: 32px;
    padding: 0 10px;
    border-bottom: 1px solid transparent;
    align-items: center;
    color: $cl;
    transition: color .2s, border-color .2s;
    &:not(:last-child) {
      margin-right: 10px; }
    &:hover {
      color: $blue; }
    &.active {
      border-color: $blue;
      color: $blue; }
    .icon {
      font-size: 30px;
      fill: $blue; } } }

.menu {
  padding-top: 85px;
  @include m {
    padding: 0; }
  &__group {
    margin-bottom: 50px; }
  &__title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    color: $dark;
    line-height: 1.44; }
  &__link {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    color: $border;
    letter-spacing: .2px;
    line-height: 1.43; } }

.back {
  display: inline-flex;
  margin-bottom: 50px;
  align-items: center;
  letter-spacing: .2px;
  .icon {
    margin-right: 15px;
    fill: $blue;
    font-size: 30px; } }

.comment {
  &__head {
    margin-bottom: 50px;
    @include m {
      margin-bottom: 30px; } }
  &__title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    color: $dark;
    line-height: 1.44; }
  &__text {
    font-size: 12px;
    color: $border; }
  &__required {
    margin-left: 4px;
    color: $blue;
    font-weight: 400; }
  &__fieldset {
    margin-bottom: 50px;
    @include m {
      margin-bottom: 30px; } }
  &__btn {
    @include m {
      display: block;
      margin: 0 auto; } } }
